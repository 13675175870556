
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { DateColumnConfig } from "@/components/controls/catalogueGrid/columns/dateColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import loggingInfoDialog from "@/components/info/LoggingInfoDialog.vue";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
  },
  computed: {
    loggingInfoDialog(): any {
      return loggingInfoDialog;
    },
  },
  data() {
    return {
      editingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        showInfo: true,
        allowDeleteAll: true,
        allowClearFilter: true,
        allowDeleteMultipleRows: false,
        deleteAllTitle: "Очистить",
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new TextColumnConfig({
          dataField: "userDescription",
          caption: "Пользователь",
          validationRules: [requiredRule],
        }),
        new DateColumnConfig({
          dataField: "date",
          caption: "Дата",
          mode: "datetime",
          validationRules: [requiredRule],
          sortOrder: "desc",
        }),
        new TextColumnConfig({
          dataField: "typeDescription",
          caption: "Тип",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "activity",
          caption: "Активность",
          validationRules: [requiredRule],
        }),
      ],
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: !this.selectMode ? "calc(100vh - 205px)" : "400px",
        horizontalScroll: true,
      }),
      selectionConfig: new TableSelectionConfig({
        mode: "single",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }
  },
});
